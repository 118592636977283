<template>
    <v-card>
        <h1 class="text-center my-4">Pusher Test</h1>
        <div>
            <v-alert
                v-for="(item, index) in messages"
                :key="index"
                outlined
                type="warning"
                prominent
                icon="mdi-account"
                class="mb-2"
                border="left">
                <span class="font-weight-bold">{{ item.chatId }}</span>
                <span class="d-block black--text">{{ item.content }}</span>
            </v-alert>
        </div>
    </v-card>
</template>

<script>
import Pusher from "pusher-js";
export default {
    data() {
        return {
            messages: [],
            pusher: null,
            channel: null,
        };
    },

    mounted() {
        Pusher.logToConsole = true;
        this.pusher = new Pusher("750f63f9cc7b3ff4586c", {
            cluster: "us3",
        });
        this.channel = this.pusher.subscribe("01messagesShamsAlghareeChannelSecret");
        this.channel.bind("1", (data) => {
            // alert(JSON.stringify(data));
            // console.log("-------------------------------------");
            // console.log(data);
            // let za = JSON.parse(data);
            // // console.log("+++++++++++++++++++++++++++" + za);
            this.messages.push(data);
            // this.messages = data;
            // // console.log("pusher" + JSON.stringify(data));
            // // console.log("just data" + data);
            // // console.log("pusher user" + JSON.stringify(data.user));
        });
    },
};
</script>

<style>
</style>
